import { debounce } from 'quasar'
import { EventBus } from 'src/core/event/EventBus'
import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'

export default {
  name: 'FilterBox',
  emits: ['brandChanged'],
  data: () => ({
    buscaGeralSelect: '',
    buscaGeralItensRaw: [],
    buscaGeralItens: [],
    marcas: [
      { id: 36, name: 'volkswagen', imagem: 'fotos-marcas/marcas/volkswagen.webp', isActive: false },
      { id: 14, name: 'fiat', imagem: 'fotos-marcas/marcas/fiat.webp', isActive: false },
      { id: 5, name: 'chevrolet', imagem: 'fotos-marcas/marcas/chevrolet.webp', isActive: false },
      { id: 35, name: 'toyota', imagem: 'fotos-marcas/marcas/toyota.png', isActive: false }
    ]
  }),
  watch: {
    busca_geral: {
      handler: debounce(function () {
        this.atualizarBusca()
      }, 3000),
      deep: true
    }
  },
  methods: {
    filterFn (valor, update, abort) {
      let val = valor.replace('R$', '').replace('.', '').replace(',00', '').replace('mil', '000').trim()
      if (val.length < 2) {
        abort()
        return
      }

      let valorBusca = parseInt(val.replace(' ', ''))

      update(() => {
        if (!isNaN(valorBusca)) {
          if (valorBusca.toString().length < 4) {
            valorBusca = 1000 * valorBusca
          }
          if (valorBusca < 35000) {
            this.buscaGeralItens = ['Carros de 0 a R$ 35 mil reais']
            this.buscaGeralItensRaw = ['0,35000']
          } else if ((valorBusca >= 35000) && (valorBusca < 55000)) {
            this.buscaGeralItens = ['Carros de R$ 35 mil reais a R$ 55 mil reais']
            this.buscaGeralItensRaw = ['35000,55000']
          } else if ((valorBusca >= 55000) && (valorBusca < 75000)) {
            this.buscaGeralItens = ['Carros de R$ 55 mil reais a R$ 75 mil reais']
            this.buscaGeralItensRaw = ['55000,75000']
          } else {
            this.buscaGeralItens = ['Carros de mais de R$ 75 mil reais']
            this.buscaGeralItensRaw = ['75000,3000000']
          }
        } else {
          this.buscaCarregando = true
          const requisicoesBusca = [{ tipo: 'geral', termo: 'busca_geral', label: 'Busca: ' + val, valor: val }]
          this.elasticSearchModel.buscar(requisicoesBusca, this.$store).then((retorno) => {
            const marcas = [...new Set(retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key.split('|')[0]))].filter((item) => item.toUpperCase().indexOf(this.textoBusca.toUpperCase()) > -1)
            this.buscaGeralItensRaw = [...new Set(retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key))]
            this.buscaGeralItensRaw = marcas.concat(this.buscaGeralItensRaw)
            this.buscaGeralItens = [...new Set(retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key.split('|')[0] + ' ' + item.key.split('|')[1]))]
            this.buscaGeralItens = marcas.concat(this.buscaGeralItens)
          })
        }
      })
    },
    textoInputBusca (val) {
      this.textoBusca = val
    },
    selecionarBusca (valor) {
      const elementoRaw = this.buscaGeralItensRaw[this.buscaGeralItens.indexOf(valor)].split(',')
      if (elementoRaw.length > 1 && this.buscaGeralItensRaw[this.buscaGeralItens.indexOf(valor)].indexOf(',')) {
        if (window.location.href.indexOf('comprar') === -1) {
          this.$router.push({
            name: 'comprar',
            query: {
              'valor_venda_pf': 'Faixa+de+Preço: ' + valor + ' |' + this.buscaGeralItensRaw[this.buscaGeralItens.indexOf(valor)]
            }
          })
        } else {
          EventBus.$emit('faixa_preco', { min: elementoRaw[0], max: elementoRaw[1] })
        }
      } else {
        // const elementoRaw = this.buscaGeralItensRaw[this.buscaGeralItens.indexOf(valor)].split('|')
        const item = this.buscaGeralItensRaw.find(i => {
          if (i === valor) {
            return true
          } else {
            const parts = i.split('|')
            if (parts.length > 1 && (parts[0] + ' ' + parts[1]) === valor) {
              return true
            }
          }
          return false
        })
        const elementoRaw = item.split('|')
        if (elementoRaw.length > 1) {
          if (window.location.href.indexOf('comprar') === -1) {
            this.$router.push({
              name: 'comprar',
              query: {
                'marca_objeto.marca_nome.keyword': 'Marca:,,,' + elementoRaw[0].replace(/ /g, ',,,').replace(/-/g, '...') + ' |' + elementoRaw[0].replace(/ /g, ',,,').replace(/-/g, '...') + ' ',
                'modelo_objeto.modelo_nome.keyword': 'Modelo:,,,' + elementoRaw[1].replace(/ /g, ',,,').replace(/-/g, '...') + ' |' + elementoRaw[1].replace(/ /g, ',,,').replace(/-/g, '...') + ' '
              }
            })
          } else {
            EventBus.$emit('busca_marca_modelo', { marca: elementoRaw[0].replace(/ /g, ',,,').replace(/-/g, '...'), modelo: elementoRaw[1].replace(/ /g, ',,,').replace(/-/g, '...') })
          }
        } else {
          if (window.location.href.indexOf('comprar') === -1) {
            this.$router.push({
              name: 'comprar',
              query: {
                'marca_objeto.marca_nome.keyword': 'Marca:,,,' + elementoRaw[0].replace(/ /g, ',,,').replace(/-/g, '...') + ' |' + elementoRaw[0].replace(/ /g, ',,,').replace(/-/g, '...') + ' '
              }
            })
          } else {
            EventBus.$emit('busca_marca_modelo', { marca: elementoRaw[0].replace(/ /g, ',,,').replace(/-/g, '...') })
          }
        }
      }
    },
    atualizarBusca () {
      if (this.buscaGeralSelect !== '') {
        if (window.location.href.indexOf('comprar') > -1) {
          EventBus.$emit('busca_geral', this.buscaGeralSelect)
        } else {
          this.$router.push({
            name: 'comprar',
            query: { busca_geral: this.buscaGeralSelect }
          })
        }
      }
    },
    buscarTag (value) {
      window.location.href = '/comprar?tags_busca_nome.keyword=Tag: ' + value + '+|' + value
    },
    selecionarMarca (item) {
      this.marcas.map((marca) => {
        marca.isActive = marca.name === item.name && !marca.isActive
      })
      const selected = this.marcas.find((marca) => marca.isActive)
      this.$emit('brandChanged', selected ? selected.id : 0)
    }
  },
  mounted () {
    this.elasticSearchModel = new ElasticSearchModel()
    const executarBusca = async () => {
      this.elasticSearchModel.buscar([], this.$store).then((retorno) => {
        this.buscaGeralItensRaw = retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key)
        this.buscaGeralItens = retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key.split('|')[0] + ' ' + item.key.split('|')[1])
      })
    }
    executarBusca()
  }
}
