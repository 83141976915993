var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-filter-wrapper"},[_c('section',{attrs:{"id":"box-filter-box"}},[_c('h6',[_vm._v("Encontre o seu seminovo")]),_c('div',{staticClass:"flex items-center no-wrap"},[_c('q-select',{staticClass:"search-input full-width",attrs:{"use-input":"","hide-selected":"","fill-input":"","input-debounce":"1000","dense":"","popup-content-class":"resultado-input","bg-color":"white","options":_vm.buscaGeralItens,"outlined":"","placeholder":"Digite o modelo ou a marca"},on:{"filter":_vm.filterFn,"input":_vm.selecionarBusca,"input-value":_vm.textoInputBusca,"keyup":_vm.atualizarBusca},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"app:icon-magnifying-glass"}})]},proxy:true},{key:"no-option",fn:function(){return [_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v("\n              Nenhum resultado encontrado\n            ")])],1),_c('q-item',{attrs:{"clickable":""},on:{"click":function($event){return _vm.$router.push({ name: 'comprar' })}}},[_c('q-item-section',{staticClass:"text-grey"},[_c('b',[_vm._v("Ver todas as ofertas")])])],1)]},proxy:true},{key:"option",fn:function(scope){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              scope.index === 0 &&
                _vm.buscaGeralItensRaw[scope.index].indexOf(',') != -1
            ),expression:"\n              scope.index === 0 &&\n                buscaGeralItensRaw[scope.index].indexOf(',') != -1\n            "}],staticClass:"serch-title"},[_vm._v("\n            FAIXAS DE PREÇO\n          ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              scope.index === 0 &&
                _vm.buscaGeralItensRaw[scope.index].indexOf('|') === -1 &&
                _vm.buscaGeralItensRaw[scope.index].indexOf(',') === -1
            ),expression:"\n              scope.index === 0 &&\n                buscaGeralItensRaw[scope.index].indexOf('|') === -1 &&\n                buscaGeralItensRaw[scope.index].indexOf(',') === -1\n            "}],staticClass:"serch-title"},[_vm._v("\n            MARCAS\n          ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              scope.index ===
                _vm.buscaGeralItensRaw.findIndex(function (item) { return item.indexOf('|') > -1; })
            ),expression:"\n              scope.index ===\n                buscaGeralItensRaw.findIndex(item => item.indexOf('|') > -1)\n            "}],staticClass:"serch-title"},[_vm._v("\n            MODELOS\n          ")]),_c('q-item',{staticClass:"cursor-pointer",attrs:{"attr":"modelo","clickable":""},on:{"click":function($event){return _vm.selecionarBusca(scope.opt)}}},[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(
                  scope.opt.replace(
                    new RegExp('(' + _vm.textoBusca + ')', 'gi'),
                    '<b>$1</b>'
                  )
                )}})],1)],1)]}}]),model:{value:(_vm.buscaGeralSelect),callback:function ($$v) {_vm.buscaGeralSelect=$$v},expression:"buscaGeralSelect"}})],1),_c('ul',{staticClass:"brand-selector"},_vm._l((_vm.marcas),function(item,index){return _c('li',{key:index},[_c('span',{class:{ active: item.isActive },on:{"click":function($event){return _vm.selecionarMarca(item)}}},[_c('img',{attrs:{"src":item.imagem,"alt":item.name}})])])}),0),_c('router-link',{staticClass:"ver-mais",attrs:{"to":{ name: 'comprar' }}},[_vm._v("\n      Ver todas as marcas\n      "),_c('q-icon',{attrs:{"color":"white","size":"1.5rem","name":"svguse:icones/layout/chevron-right.svg#icon|0 0 512 512"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }